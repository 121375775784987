import { useLogService } from 'admin-portal-shared-services';
import { useCallback } from 'react';

interface IUseErrorLog {
  persistError: (error: unknown) => void;
}

export const useDtcLog = (): IUseErrorLog => {
  const log = useLogService();

  const persistError = useCallback(
    (error: unknown) => {
      // @ts-expect-error: The response could not exists if this ins't an axios error
      const { status } = error?.response ?? {};

      if (!status) {
        return log.error(error);
      }

      if (status >= 500) {
        return log.error(error);
      }

      return undefined;
    },
    [log]
  );

  return { persistError };
};
