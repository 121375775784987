import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { EnvConfig } from 'contexts';
import { ApplicationProviders } from 'contexts/providers';
import { SegmentProvider } from 'contexts/providers/SegmentProvider';
import { IEnvProps } from 'interfaces/IEnv';
import GlobalStyle from 'layouts/global';
import { FC } from 'react';
import Router from './router';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans, sans-serif',
  },
});

const App: FC<IEnvProps> = (props: EnvConfig, env) => {
  const { segmentKey } = props;

  return (
    <SegmentProvider writeKey={segmentKey}>
      <GlobalStyle />
      <ApplicationProviders env={env}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
      </ApplicationProviders>
    </SegmentProvider>
  );
};

export default App;
