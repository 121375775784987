import { EnvProvider } from 'contexts/providers/EnvProvider';
import { useDtcLog } from 'hooks/useDtcLog';
import { IEnvProps } from 'interfaces/IEnv';
import { FC, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import GlobalStateProvider from 'redux/Store';
import { CountryProvider } from './CountryProvider';
import { SidebarProvider } from './SidebarProvider';

interface IApplicationProviders {
  children: ReactNode;
  env: IEnvProps;
}

export const ApplicationProviders: FC<IApplicationProviders> = ({ children, env }) => {
  const { persistError } = useDtcLog();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 0,
        retry: false,
        refetchOnWindowFocus: false,
        onError: persistError,
      },
    },
  });

  return (
    <BrowserRouter>
      <EnvProvider env={env}>
        <GlobalStateProvider>
          <CountryProvider>
            <SidebarProvider>
              <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
            </SidebarProvider>
          </CountryProvider>
        </GlobalStateProvider>
      </EnvProvider>
    </BrowserRouter>
  );
};
