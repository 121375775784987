import { CategorySearch, Home, Store, User } from '@hexa-ui/icons';
import { ROUTE_NAMES, useApiaryNavigation } from 'hooks/useApiaryNavigation';
import { formatMessage } from 'i18n/formatters';
import { IMenuItem } from 'interfaces/IMenuItem';
import { useMemo } from 'react';

interface IUseMenuLinks {
  sidebarLinks: IMenuItem[];
}

export const useMenuLinks = (): IUseMenuLinks => {
  const { getUrl } = useApiaryNavigation();

  const sidebarLinks = useMemo<IMenuItem[]>(
    () => [
      {
        title: formatMessage({ id: 'CONSTS.HOME' }),
        path: getUrl({ path: ROUTE_NAMES.HOME }) ?? '',
        icon: () => <Home size="large" />,
        enabled: true,
        id: 'homepage',
      },
      {
        title: formatMessage({ id: 'CONSTS.SELLERS' }),
        path: getUrl({ path: ROUTE_NAMES.SELLER_SEARCH }) ?? '',
        icon: () => <Store size="large" />,
        enabled: true,
        id: 'sellers',
      },
      {
        title: formatMessage({ id: 'CONSTS.CUSTOMERS' }),
        path: getUrl({ path: ROUTE_NAMES.CUSTOMER_SEARCH }) ?? '',
        icon: () => <User size="large" />,
        enabled: true,
        id: 'customers',
      },
      {
        title: formatMessage({ id: 'CONSTS.CATEGORIES' }),
        path: getUrl({ path: ROUTE_NAMES.CATEGORIES_LIST }) ?? '',
        icon: () => <CategorySearch size="large" />,
        enabled: true,
        id: 'categories',
      },
    ],
    [getUrl]
  );

  return { sidebarLinks };
};
