import MessageMap from '../i18n.d';

const ptBR: MessageMap = {
  test: {
    translate: 'Tradução',
    missing: 'Apenas a opção em português está disponível.',
  },
};

export default ptBR;
