import { createIntl, createIntlCache, defineMessages } from 'react-intl';
import MessageMap from './i18n.d';
import enUS from './languages/en-US';
import ptBR from './languages/pt-BR';

const DEFAULT_LANGUAGE = 'en-US';
const MESSAGES: { [language: string]: MessageMap } = {
  'en-US': enUS,
  'pt-BR': ptBR,
};

const intlProvider = (locale: string) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: defineMessages(flattenObject(MESSAGES[locale])),
    },
    cache
  );
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

export default intlProvider(DEFAULT_LANGUAGE);
