import { useAuthenticationService } from 'admin-portal-shared-services';
import { BeeSpinner } from 'components/BeeSpinner';
import { countryList } from 'consts/countryList';
import { routes } from 'consts/routes';
import { ROUTE_NAMES, useApiaryNavigation } from 'hooks/useApiaryNavigation';
import { useCountry } from 'hooks/useCountry';
import { ICountry } from 'interfaces/ICountry';
import { Suspense, lazy, useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';

const {
  COUNTRY_SELECTION,
  HOME,
  SELLER_SEARCH,
  SELLER_TOOLS,
  SELLER_DETAILS,
  SELLER_ORDERS_LIST,
  SELLER_ORDER_DETAILS,
  CUSTOMER_SEARCH,
  CUSTOMER_TOOLS,
  CUSTOMER_DETAILS,
  CUSTOMER_ORDERS_LIST,
  CUSTOMER_ORDER_DETAILS,
  CATEGORIES_LIST,
  CATEGORY_DETAILS,
} = routes;

const Error403Page = lazy(() => import('pages/403Page'));
const CountrySelectionPage = lazy(() => import('pages/CountrySelectionPage'));
const HomePage = lazy(() => import('pages/HomePage'));

const SellerSearchPage = lazy(() => import('pages/Seller/SearchPage'));
const SellerToolsPage = lazy(() => import('pages/Seller/ToolsPage'));
const SellerDetailsPage = lazy(() => import('pages/Seller/DetailsPage'));
const SellerOrdersListPage = lazy(() => import('pages/Seller/OrdersListPage'));
const SellerOrderDetailsPage = lazy(() => import('pages/Seller/OrderDetailsPage'));

const CustomerSearchPage = lazy(() => import('pages/Customer/SearchPage'));
const CustomerToolsPage = lazy(() => import('pages/Customer/ToolsPage'));
const CustomerDetailsPage = lazy(() => import('pages/Customer/DetailsPage'));
const CustomerOrdersListPage = lazy(() => import('pages/Customer/OrdersListPage'));
const CustomerOrderDetailsPage = lazy(() => import('pages/Customer/OrderDetailsPage'));

const CategoriesPage = lazy(() => import('pages/Category/ListPage'));
const CategoryDetailsPage = lazy(() => import('pages/Category/DetailsPage'));

function Router(): JSX.Element {
  const instancedRoutes = useRoutes([
    { path: COUNTRY_SELECTION, element: <CountrySelectionPage /> },
    { path: HOME, element: <HomePage /> },
    { path: '*', element: <Error403Page /> },
    { path: SELLER_SEARCH, element: <SellerSearchPage /> },
    { path: SELLER_TOOLS, element: <SellerToolsPage /> },
    { path: SELLER_DETAILS, element: <SellerDetailsPage /> },
    { path: SELLER_ORDERS_LIST, element: <SellerOrdersListPage /> },
    { path: SELLER_ORDER_DETAILS, element: <SellerOrderDetailsPage /> },
    { path: CUSTOMER_SEARCH, element: <CustomerSearchPage /> },
    { path: CUSTOMER_TOOLS, element: <CustomerToolsPage /> },
    { path: CUSTOMER_DETAILS, element: <CustomerDetailsPage /> },
    { path: CUSTOMER_ORDERS_LIST, element: <CustomerOrdersListPage /> },
    { path: CUSTOMER_ORDER_DETAILS, element: <CustomerOrderDetailsPage /> },
    { path: CATEGORY_DETAILS, element: <CategoryDetailsPage /> },
    { path: CATEGORIES_LIST, element: <CategoriesPage /> },
  ]);
  const { navigate } = useApiaryNavigation();
  const { country, changeCountry } = useCountry();
  const location = useLocation();
  const supportedCountries = useAuthenticationService().getSupportedCountries();

  const countryFromUrl = ((): ICountry | null | undefined => {
    const countryCode = location.pathname.slice(8, 10);
    const availableCountryCode = supportedCountries?.find((code) => code === countryCode);
    const countryItem = countryList?.find(
      ({ code }) => availableCountryCode && code === availableCountryCode
    );
    if (countryItem && countryItem?.code !== country?.code) {
      changeCountry?.(countryItem?.code);
    }
    return countryItem;
  })();

  useEffect(() => {
    if (!countryFromUrl && location.pathname !== COUNTRY_SELECTION) {
      navigate({ path: ROUTE_NAMES.ERROR_403 });
    }
    // If the dependency array below receives the navigate variable, a infinite loop begins
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <Suspense fallback={<BeeSpinner />}>{instancedRoutes}</Suspense>;
}

export default Router;
