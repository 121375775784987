import { routes } from 'consts/routes';
import { useNavigate } from 'react-router-dom';
import { urlHelper } from 'utils/helpers';
import { useCountry } from './useCountry';

export enum ROUTE_NAMES {
  ERROR_403 = 'ERROR_403',
  COUNTRY_SELECTION = 'COUNTRY_SELECTION',
  HOME = 'HOME',
  SELLER_SEARCH = 'SELLER_SEARCH',
  SELLER_TOOLS = 'SELLER_TOOLS',
  SELLER_DETAILS = 'SELLER_DETAILS',
  SELLER_ORDERS_LIST = 'SELLER_ORDERS_LIST',
  SELLER_ORDER_DETAILS = 'SELLER_ORDER_DETAILS',
  CUSTOMER_SEARCH = 'CUSTOMER_SEARCH',
  CUSTOMER_TOOLS = 'CUSTOMER_TOOLS',
  CUSTOMER_DETAILS = 'CUSTOMER_DETAILS',
  CUSTOMER_ORDERS_LIST = 'CUSTOMER_ORDERS_LIST',
  CUSTOMER_ORDER_DETAILS = 'CUSTOMER_ORDER_DETAILS',
  CATEGORIES_LIST = 'CATEGORIES_LIST',
  CATEGORY_DETAILS = 'CATEGORY_DETAILS',
}

interface INavigateParams {
  path: string;
  params?: { [key: string]: string | undefined };
  state?: unknown;
}

interface IUseApiaryNavigationResponse {
  navigate: (params: INavigateParams) => void;
  getUrl: (params: INavigateParams) => string;
}

interface StringIndex {
  [key: string]: string;
}

export const useApiaryNavigation = (): IUseApiaryNavigationResponse => {
  const navigation = useNavigate();
  const { country } = useCountry();

  const getUrl = ({ path, params }: INavigateParams) => {
    const router = routes as StringIndex;
    const keys = Object.keys(router);
    const keyValue = keys.find((key) => key === String(path));
    if (!keyValue) return '';
    const countryCode = country?.code ?? params?.countryCode;
    if (!countryCode && path !== ROUTE_NAMES.COUNTRY_SELECTION) return routes.ERROR_403;

    return urlHelper.urlBuilder(router[keyValue], {
      countryCode,
      ...params,
    });
  };

  const navigate = ({ path, params, state = {} }: INavigateParams) => {
    const url = getUrl({ path, params });
    navigation(url, { state });
  };

  return {
    navigate,
    getUrl,
  };
};
