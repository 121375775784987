interface IUseListFilterProps<T> {
  list?: T[];
  searchKeys: string[];
  searchValue: string;
}

const regex = /[[\]{}()*+?.,\\^$|#\s]/g;

const filterList = <T>({ list = [], searchKeys, searchValue }: IUseListFilterProps<T>): T[] => {
  if (!list) return [];
  if (searchValue?.length === 0 || searchKeys?.length === 0) return list;
  const stringRegex = new RegExp(searchValue.replace(regex, '\\$&'), 'i');
  const filteredList = list.filter((listItem) => {
    let containsString = false;
    for (const key in searchKeys) {
      if (Object.prototype.hasOwnProperty.call(searchKeys, key)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let property = listItem as any; // The any is necessary because it could access anything inside any object
        searchKeys[key].split('.').forEach((keyItem) => {
          property = property?.[keyItem];
        });
        containsString = stringRegex.test(String(property));
        if (containsString) {
          break;
        }
      }
    }

    return containsString;
  });

  return filteredList;
};

export default filterList;
