import { Grid } from '@hexa-ui/components';
import { useSidebar } from 'admin-portal-shared-services';
import { useMenuLinks } from 'hooks/useMenuLinks';
import React, { ReactNode } from 'react';
import { AppContainer } from './styles';

const { Container } = Grid;

interface ISidebarProviderProps {
  children: ReactNode;
}

export const SidebarProvider = ({ children }: ISidebarProviderProps): JSX.Element => {
  const { sidebarLinks } = useMenuLinks();

  useSidebar({ items: sidebarLinks, utils: [] });

  return (
    <Container sidebar type="fluid">
      <AppContainer>{children}</AppContainer>
    </Container>
  );
};
