import { formatInTimeZone } from 'date-fns-tz';

type TDate = number | Date | string;

export enum EDateType {
  SHORT = 'SHORT',
  LONG = 'LONG',
  WEEK = 'WEEK',
  GMT = 'GMT',
  GMT24H = 'GMT24H',
  TIMEZONE = 'TIMEZONE',
}

interface FormatDateByTimezoneProps {
  date: TDate;
  timezoneString: string | undefined;
  preferredTimeFormat: string | undefined;
}

export const dateFormat = {
  // 17 Mar 2023
  [EDateType.SHORT]: 'dd MMM yyyy',
  // Jan 5, 2023 - 1:29 pm
  [EDateType.LONG]: 'MMM dd, yyyy - HH:mm aaa',
  // Friday, Dec 16
  [EDateType.WEEK]: 'iiii, MMM dd',
  // Mon, Oct 31, 2022, 2:12:07 PM GMT+4
  [EDateType.GMT]: 'iii, MMM dd, yyyy, h:mm:ss a O',
  // Thu, Mar 16, 2023, 11:59:59 GMT+4
  [EDateType.GMT24H]: 'iii, MMM dd, yyyy, HH:mm:ss O',
  // Mon, Oct 31, 2022, 14:12:07 CST
  [EDateType.TIMEZONE]: 'iii, MMM dd, yyyy, HH:mm:ss zzz',
};

const formatDateByTimezone = ({
  date,
  timezoneString = EDateType.GMT,
  preferredTimeFormat = 'h:mm a',
}: FormatDateByTimezoneProps) => {
  const dateType: EDateType =
    {
      'h:mm a': EDateType.GMT,
      'H:mm': EDateType.GMT24H,
    }[preferredTimeFormat] || EDateType.GMT;

  return formatInTimeZone(date, timezoneString, dateFormat[dateType]);
};

export default formatDateByTimezone;
