import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, FC, ReactNode, useMemo } from 'react';

interface ISegmentProviderProps {
  writeKey: string;
  children: ReactNode;
}

export const SegmentContext = createContext<AnalyticsBrowser | undefined>(undefined);

export const SegmentProvider: FC<ISegmentProviderProps> = ({ children, writeKey }) => {
  const analytics = useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  return <SegmentContext.Provider value={analytics}>{children}</SegmentContext.Provider>;
};
