import MessageMap from '../i18n.d';

const enUS: MessageMap = {
  APPLICATION_403_ERROR: {
    HEADER: 'Hmm... Access denied!',
    DESCRIPTION: "It looks like this page wasn't found or doesn't exist.",
    BUTTON_LABEL: 'HOME',
  },
  BREADCRUMB: {
    HOMEPAGE: 'Retailer App Monitoring DTC Home',
    DETAILS: 'Details',
    SELLERS: 'Sellers',
    SELLER_TOOLS: 'Seller Tools',
    SELLER_DETAILS: 'Details',
    CUSTOMERS: 'Customers',
    CUSTOMER_TOOLS: 'Customer Tools',
    ORDERS_DETAILS: 'Orders Details',
    ORDERS: 'Orders',
    CATEGORIES: 'Categories',
  },
  CATEGORIES: {
    PAGE_TITLE: 'Categories',
  },
  CATEGORY_DETAILS: {
    HEADER_TITLE: 'Categories',
    PAGE_TITLE: 'Category Details',
    CARD_TITLE: 'Category',
    MICROSERVICE_ID: 'Microservice ID',
    STATUS: 'Status',
    ITEM: 'Item',
    ITEM_PLATFORM_ID: 'Item Platform ID',
    SUBCATEGORIES: 'Subcategories',
    ITEMS_ASSIGNED: 'Items Assigned',
    ITEM_FILTER_PLACEHOLDER: 'Search an Item Name or Vendor Item ID',
    ITEM_TABLE_HEADER: 'Item',
    STATUS_TABLE_HEADER: 'Status',
    ITEM_PLATFORM_ID_TABLE_HEADER: 'Item Platform ID',
    HIDDEN_TABLE_HEADER: 'Hidden',
    NARCOTIC_TABLE_HEADER: 'Narcotic',
    ALCOHOLIC_TABLE_HEADER: 'Alcoholic',
    ITEMS_TABLE_ERROR: 'These items are not being displayed in the BEES App through this category.',
    CATEGORY_ERROR:
      'This category contains subcategories and items at the same time, but it can only display the subcategories in the BEES App.',
  },
  COUNTRY_SELECTOR: {
    NO_COUNTRY_DISCLAIMER:
      'Your account does not yet have access to any countries. Please contact your administrator.',
    BEES_APIARY_DTC_HOME_LABEL: 'Retailer App Monitoring DTC',
    LANGUAGE: 'Language:',
  },
  GENERAL: {
    NAME_LABEL: 'Name',
    EMAIL_LABEL: 'E-mail',
    PHONE_NUMBER_LABEL: 'Phone number',
  },
  HOMEPAGE: {
    TITLE: 'What information would you like to see?',
    SELLER_CARD_TITLE: 'Seller',
    SELLER_CARD_DESCRIPTION: 'See seller details, addresses & orders.',
    CUSTOMER_CARD_TITLE: 'Customer',
    CUSTOMER_CARD_DESCRIPTION: 'See customer details, addresses & orders.',
    CATEGORIES_CARD_TITLE: 'Categories',
    CATEGORIES_CARD_DESCRIPTION: 'All categories of this country.',
  },
  CONSTS: {
    COPY: 'Copy',
    COPIED: 'Copied!',
    NOT_AVAILABLE: 'Not Available',
    SEARCH: 'Search',
    NO_RESULTS_FOUND: 'No results found for this search.',
    BEE_NOT_FOUND: 'There’s nothing here.',
    MICROSERVICE_ID: 'Microservice ID',
    HOME: 'Home',
    SELLERS: 'Sellers',
    CUSTOMERS: 'Customers',
    CATEGORIES: 'Categories',
    ITEM: 'Item',
    COMBO: 'Combo',
  },
  SELLERS: {
    TITLE: 'Seller',
    SUBTITLE: 'Insert the seller Tax ID to access the Seller details.',
    RESULTS_FOUND: '{quantity} sellers found',
    RESULT_FOUND: '{quantity} seller found',
    NO_SELLER_FOUND: 'No seller ID found.',
    PLACEHOLDER: 'Insert a valid Tax ID...',
    SELLER_TITLE: 'Seller details',
    SELLER_TEXT: 'General information & addresses.',
    ORDERS_TITLE: 'Orders',
    ORDERS_TEXT: 'All the orders made to this seller.',
    PRODUCTS_TITLE: 'Products',
    PRODUCTS_TEXT: 'This seller`s catalog.',
    DETAILS: {
      ACCOUNT_TAB: 'Account',
      BUSINESS_TAB: 'Business',
      ADDRESS_TAB: 'Address',
      TITLE: 'Details',
      GENERAL: 'General',
      ADDRESS: 'Address',
      DISPLAY_NAME: 'Display name',
      PHONE: 'Phone number',
      EMAIL: 'E-mail',
      CREATION_DATE: 'Creation date',
      PREFERRED: 'Preferred',
      STATE: 'State',
      CITY: 'City',
      LOCATION: 'Location',
      ZIPCODE: 'Zipcode',
      NEIGHTBORHOOD: 'Neighborhood',
      NUMBER: 'Number',
      COMPLEMENT: 'Complement',
      THOROUGHFARE: 'Thoroughfare',
      TAX_ID: 'Tax ID',
      CREATED_AT: 'Creation date',
      UPDATED_AT: 'Updated at',
      AVAILABILITY: 'Availability',
      SEGMENT: 'Segment',
      DELIVERY_CENTER_GROUP: 'Delivery Center Group',
      ISSUES_INVOICE: 'Issues invoice',
      REFRIGERATOR_COUNT: 'Refrigerator count',
      HAS_COLD_ROOM: 'Has cold room',
      HAS_SERVICE_ENTRY: 'Has service entry',
      BILING_ADDRESS: 'Biling address',
      DELIVERY_ADDRESS: 'Delivery address',
      NEIGHBORHOOD: 'Neighborhood',
      ZIP_CODE: 'Zip Code',
    },
  },
  CUSTOMERS: {
    TITLE: 'Customer',
    SUBTITLE: 'Insert the Account ID to access the Customer details.',
    NAME: 'Name',
    EMAIL: 'E-mail',
    PHONE: 'Phone',
    NO_CUSTOMER_FOUND: 'No customer ID found.',
    PLACEHOLDER: 'Find customer by name...',
    NO_CUSTOMERS_FOUND: 'No customers found',
    SECOND_PLACEHOLDER: 'Find customer by {name}',
    RESULTS_FOUND: '{quantity} customers found',
    RESULT_FOUND: '{quantity} customer found',
    DETAILS: {
      TITLE: 'Details',
      GENERAL: 'General',
      ADDRESS: 'Address',
      DISPLAY_NAME: 'Display name',
      PHONE: 'Phone number',
      EMAIL: 'E-mail',
      CREATION_DATE: 'Creation date',
      PREFERRED: 'Preferred',
      STATE: 'State',
      CITY: 'City',
      LOCATION: 'Location',
      ZIPCODE: 'Zipcode',
      NEIGHTBORHOOD: 'Neighborhood',
      NUMBER: 'Number',
      COMPLEMENT: 'Complement',
      THOROUGHFARE: 'Thoroughfare',
    },
  },
  LOADING: {
    ALMOST_THERE: "We're almost there...",
    LOADING_CUSTOMERS: 'Loading customers',
    LOADING_SELLERS: 'Loading sellers',
    LOADING: 'Loading',
  },
  PAGINATION: {
    ITEMS_PER_PAGE: 'Items per page',
  },
  ORDERS: {
    TITLE: 'Orders',
    INPUT_PLACEHOLDER: 'Search an order',
    NO_ORDERS_FOUND: 'No orders found',
    ORDER_NUMBER: 'Order number',
    ORDER_ID: 'Order ID',
    STATUS: 'Status',
    PLACEMENT_DATE: 'Placement date',
    DELIVERY_DATE: 'Delivery date',
    SUBTOTAL: 'Subtotal',
    TOTAL: 'Total',
  },
  ORDER_DETAILS: {
    TITLE: 'Order Details',
    SUBTOTAL: 'Subtotal',
    TAX: 'Tax',
    DISCOUNT: 'Discount',
    GRAND_TOTAL: 'Grand Total',
    SUMMARY: 'Order Summary',
    STATUS: 'Status',
    ADDRESS: 'Address',
    ERP_ORDER_NUMBER: 'ERP Order Number',
    ORDER_NUMBER: 'Order Number',
    ORDER_ID: 'Order ID',
    PAYMENT_METHOD: 'Payment Method',
    DELIVERY_DATE: 'Delivery Date',
    CHANNEL: 'Channel',
    PLACED_ON: 'Placed On',
    DELIVERY_CENTER_ID: 'Delivery Center ID',
    CANCELLATION_REASON: 'Cancellation Reason',
    COUPON_CODE: 'Coupon Code',
    CUSTOMER_NAME: 'Customer Name',
    CUSTOMER_EMAIL: 'Customer Email',
    CUSTOMER_PHONE: 'Customer Phone',
    SELLER_TAX_ID: 'Seller Tax ID',
    SUMMARY_LOCATION_TITLE: 'Location',
    SUMMARY_LOCATION_STREET_ADDRESS: 'Street address',
    SUMMARY_LOCATION_CITY: 'City',
    SUMMARY_LOCATION_COUNTRY: 'Country',
    SUMMARY_LOCATION_NEIGHBORHOOD: 'Neighborhood',
    SUMMARY_LOCATION_STATE: 'State',
    SUMMARY_LOCATION_EXTRA_ADDRESS_INFO: 'Extra address info',
    SUMMARY_LOCATION_NUMBER: 'Number',
    SUMMARY_LOCATION_ZIPCODE: 'Zipcode',
    SUMMARY_LOCATION_COORDINATES: 'Coordinates',
    SUMMARY_INVOICING_TITLE: 'Invoicing',
    SUMMARY_INVOICING_LEGAL_NAME: 'Legal name',
    SUMMARY_INVOICING_DISPLAY_NAME: 'Display name',
    SUMMARY_INVOICING_EMAIL: 'Email',
    SUMMARY_INVOICING_PHONE: 'Phone',
    TABLE_ITEM: 'Item',
    TABLE_QUANTITY: 'Quantity',
    TABLE_PRICE: 'Price',
    TABLE_TOTAL: 'Total',
    PURCHASES: 'Purchases',
    ORDER_COMMENT: 'Order Comment',
    ORDER_SCORE: 'Order Score',
    ORDER_NUMBER_VALUE: '#{orderNumber}',
  },
  TOOLS: {
    SELLER_DETAILS: {
      TITLE: 'Seller Details',
      DESCRIPTION: 'General information & addresses.',
    },
    SELLER_ORDERS: {
      TITLE: 'Orders',
      DESCRIPTION: 'All the orders made to this seller.',
    },
    PRODUCTS: {
      TITLE: 'Products',
      DESCRIPTION: "This seller's catalog",
    },
    CUSTOMER_DETAILS: {
      TITLE: 'Customer Details',
      DESCRIPTION: 'General information & addresses.',
    },
    CUSTOMER_ORDERS: {
      TITLE: 'Orders',
      DESCRIPTION: 'All the orders made by this customer.',
    },
  },
};

export default enUS;
