import { SET_CUSTOMER_CARD_BOX, SET_SELLER_CARD_BOX } from 'redux/actionTypes';
import { CardBoxStoreInterface, ICardBoxReducerAction } from '../interfaces';

const initialState: CardBoxStoreInterface = {
  seller: undefined,
  customer: undefined,
};

export default (state = initialState, action: ICardBoxReducerAction): CardBoxStoreInterface => {
  switch (action.type) {
    case SET_SELLER_CARD_BOX: {
      const { seller } = action.payload;
      return {
        ...state,
        seller,
      };
    }
    case SET_CUSTOMER_CARD_BOX: {
      const { customer } = action.payload;
      return {
        ...state,
        customer,
      };
    }

    default:
      return state;
  }
};
