import format from 'date-fns/format';
import { EDateType, dateFormat } from './formatDateByTimezone';

type TDate = number | Date | string | undefined;
export type TDateType = (typeof EDateType)[keyof typeof EDateType];

interface FormattedDateByLocaleProps {
  date: TDate | undefined;
  locale: string | undefined;
  type: TDateType;
}

const formattedDateByLocale = ({ date, locale, type }: FormattedDateByLocaleProps): string => {
  if (!locale || !date || !type) {
    return '';
  }

  const dateObj = new Date(date);
  const dtDateOnly = new Date(dateObj.valueOf() + dateObj.getTimezoneOffset() * 60 * 1000);
  const formattedString = format(dtDateOnly, dateFormat[type]);

  if (type === EDateType.GMT) {
    return formattedString.slice(0, -2);
  }

  return formattedString;
};

export default formattedDateByLocale;
