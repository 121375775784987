const BASE_PATH = '/apiary';
const COUNTRY_PATH = `${BASE_PATH}/:countryCode`;
const SELLERS_BASE_PATH = `${COUNTRY_PATH}/sellers`;
const CUSTOMER_BASE_PATH = `${COUNTRY_PATH}/customers`;

const ERROR_403 = `${BASE_PATH}/403`;
const COUNTRY_SELECTION = `${BASE_PATH}`;
const HOME = `${COUNTRY_PATH}/home`;

const SELLER_SEARCH = `${SELLERS_BASE_PATH}/search`;
const SELLER_TOOLS = `${SELLERS_BASE_PATH}/:sellerId`;
const SELLER_DETAILS = `${SELLER_TOOLS}/details`;
const SELLER_ORDERS_LIST = `${SELLER_TOOLS}/orders`;
const SELLER_ORDER_DETAILS = `${SELLER_ORDERS_LIST}/:orderId`;

const CUSTOMER_SEARCH = `${CUSTOMER_BASE_PATH}/search`;
const CUSTOMER_TOOLS = `${CUSTOMER_BASE_PATH}/:customerId`;
const CUSTOMER_DETAILS = `${CUSTOMER_TOOLS}/details`;
const CUSTOMER_ORDERS_LIST = `${CUSTOMER_TOOLS}/orders`;
const CUSTOMER_ORDER_DETAILS = `${CUSTOMER_ORDERS_LIST}/:orderId`;

const CATEGORIES_LIST = `${COUNTRY_PATH}/categories`;
const CATEGORY_DETAILS = `${CATEGORIES_LIST}/:categoryId`;

export const routes = {
  ERROR_403,
  COUNTRY_SELECTION,
  HOME,
  SELLER_SEARCH,
  SELLER_TOOLS,
  SELLER_DETAILS,
  SELLER_ORDERS_LIST,
  SELLER_ORDER_DETAILS,
  CUSTOMER_SEARCH,
  CUSTOMER_TOOLS,
  CUSTOMER_DETAILS,
  CUSTOMER_ORDERS_LIST,
  CUSTOMER_ORDER_DETAILS,
  CATEGORIES_LIST,
  CATEGORY_DETAILS,
};
