import { countryList } from 'consts/countryList';
import { ICountry } from 'interfaces/ICountry';
import { FC, ReactNode, createContext, useMemo, useState } from 'react';

export interface ICountryStore {
  changeCountry?: (country?: string) => void;
  eraseCountry?: () => void;
  country?: ICountry;
}

interface ICountryProviderProps {
  children: ReactNode;
}

const initialStore: ICountryStore = {
  country: undefined,
  changeCountry: undefined,
  eraseCountry: undefined,
};

export const CountryContext = createContext(initialStore);

export const CountryProvider: FC<ICountryProviderProps> = ({ children }) => {
  const [country, setCountry] = useState<ICountry | undefined>(() => {
    // eslint-disable-next-line no-restricted-globals
    const urlCountryCode = location.pathname.slice(8, 10);
    const initialCountry = countryList?.find(({ code }) => code === urlCountryCode);
    return initialCountry;
  });

  const changeCountry = (newCountryCode?: string) => {
    if (!newCountryCode) return;

    const foundCountry = countryList.find((currCountry) => currCountry.code === newCountryCode);
    setCountry(foundCountry);
  };

  const eraseCountry = () => {
    setCountry(undefined);
  };

  const values = useMemo(() => ({ country, changeCountry, eraseCountry }), [country]);

  return <CountryContext.Provider value={values}>{children}</CountryContext.Provider>;
};
